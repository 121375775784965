






















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import servertranslate from '@/filters/server-translate';
import { Exam, ExamBasicAttributes } from '../../types/exam.type';

@Component({
  components: {},
})
export default class ExamView extends Vue {
  @Prop(Object) readonly exam!: Exam;

  @Prop(Object) readonly examBasicAttrs!: ExamBasicAttributes;

  servertranslate = servertranslate;

  get instructorName() {
    return this.exam.examInfo.instructorData?.id
      ? this.servertranslate(this.exam.examInfo.instructorData?.name)
      : '';
  }
}
