































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DialogComponent from '@/components/dialog.component.vue';
import servertranslate from '@/filters/server-translate';
import { Sector } from '@/types/exam.type';
import { Lang } from '@/types/general.type';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    DialogComponent,
  },
})
export default class DistributeSectorsDialog extends mixins(Toaster) {
  @Prop(Array) readonly sectors!: Sector[];

  @Prop(String) readonly examDate!: string;

  @Prop(String) readonly appId!: string;

  @Prop(String) readonly courseId!: string;

  @Prop(String) readonly examId!: string;

  @Prop(String) readonly courseCode!: string;

  sectorCode = '';

  servertranslate = servertranslate;

  showDistributeDialog = false;

  showConfirmationDialog = false;

  loading = false;

  selectedSectorsIds = [];

  get examDateStr(): string {
    const date = new Date(this.examDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    const formattedYear = String(year).slice(2);
    return `${formattedDay}${formattedMonth}${formattedYear}`;
  }

  get MODEL_CODE_EXAMPLE(): string {
    const MODEL_CODE = '01';
    return this.examDateStr && this.courseCode
      ? `*${this.sectorCode}${this.examDateStr}${MODEL_CODE}${this.courseCode}*`
      : '';
  }

  get canDistribute(): boolean {
    return this.selectedSectorsIds && this.selectedSectorsIds.length > 0;
  }

  open() {
    this.selectedSectorsIds = [];
    this.showDistributeDialog = true;
  }

  close() {
    this.showDistributeDialog = false;
  }

  get filteredSelectedSectors(): string[] {
    return this.selectedSectorsIds.filter((sector) => sector);
  }

  onDistributeClick() {
    this.showConfirmationDialog = true;
    this.sectorCode = this.sectors.find((sector) => sector.id === this.filteredSelectedSectors[0])
      ?.code as string;
  }

  closeConfirmationDialog() {
    this.showConfirmationDialog = false;
  }

  async onConfirmationClick() {
    this.closeConfirmationDialog();
    this.close();
    await this.confirmDistribution();
  }

  async confirmDistribution() {
    try {
      this.loading = true;
      const { success, message } = await ExamGenerationProvider.distributeExamSectors(
        this.appId,
        this.courseId,
        this.examId,
        this.filteredSelectedSectors,
      );
      this.loading = false;
      if (success) {
        window.location.reload();
      } else {
        this.errorToaster(message, { duration: 5000 });
      }
    } catch (err) {
      this.loading = false;
      this.errorToaster(err.message as Lang, { duration: 5000 });
    }
  }
}
