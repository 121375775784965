































































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DialogComponent from '@/components/dialog.component.vue';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import { Exam, LabelsConfig, Model } from '@/types/exam.type';
import servertranslate from '@/filters/server-translate';
import { EXPORT_EXAM, MODELS_MATCH } from '../../enums';
import Toaster from '../../mixins/toaster.mixin';
import { Lang } from '../../types/general.type';

@Component({
  components: {
    DialogComponent,
  },
})
export default class PublishTypeDialogComponent extends mixins(Toaster) {
  @Prop(String) readonly appId!: string;

  @Prop(String) readonly examStatus!: string;

  @Prop(String) readonly modelsMatch!: string;

  @Prop(Object) readonly exam!: Exam;

  @Prop(Array) readonly publishConfirmationRestrictions!: string[];

  @Prop(Object) readonly labelsConfig!: LabelsConfig;

  servertranslate = servertranslate;

  showConfirmationDialog = false;

  showWarnDialog = false;

  loading = false;

  publishOption = '';

  message: Lang | null = null;

  model: Model = {};

  EXPORT_EXAM = EXPORT_EXAM;

  confirmationRestrictions = [];

  get canPublish(): boolean {
    if (this.publishOption === EXPORT_EXAM.ELECTRONIC_EXAM) {
      let restrictionsCount = 0;
      this.confirmationRestrictions.forEach((restriction) => {
        if (restriction) restrictionsCount += 1;
      });
      return restrictionsCount === this.publishConfirmationRestrictions.length;
    } if (this.publishOption === EXPORT_EXAM.ELECTRONIC_CORRECTION) {
      return true;
    }
    return false;
  }

  get questionsCount(): number | number[] {
    let count = 0;
    if (this.exam.examInfo.modelsMatch === MODELS_MATCH.PERFECT_MATCH) {
      this.exam.examInfo.questionTypesCounts.forEach((question) => {
        count += question.questionCount;
      });
    } else if (this.exam.examInfo.modelsMatch === MODELS_MATCH.NOT_MATCHING) {
      if (this.exam.examInfo.modelsQuestionsCounts && this.exam.examInfo.modelsQuestionsCounts.length) {
        let sameCount = true;
        for (let i = 0; i < this.exam.examInfo.modelsQuestionsCounts.length - 1; i += 1) {
          if (this.exam.examInfo.modelsQuestionsCounts[i] !== this.exam.examInfo.modelsQuestionsCounts[i + 1]) {
            sameCount = false;
            break;
          }
        }
        return sameCount
          ? this.exam.examInfo.modelsQuestionsCounts[0]
          : this.exam.examInfo.modelsQuestionsCounts;
      }
    }
    return count;
  }

  open() {
    this.confirmationRestrictions = [];
    if ([EXPORT_EXAM.ELECTRONIC_CORRECTION, EXPORT_EXAM.ELECTRONIC_EXAM]
      .includes(this.examStatus)) {
      this.showWarnDialog = true;
    } else {
      this.showConfirmationDialog = true;
    }
  }

  close() {
    this.showConfirmationDialog = false;
  }

  closeWarningDialog() {
    this.showWarnDialog = false;
  }

  get canElectronicPublish() {
    return this.modelsMatch !== MODELS_MATCH.NOT_MATCHING;
  }

  onWarningClick() {
    this.showWarnDialog = false;
    this.showConfirmationDialog = true;
  }

  async onPublishClick() {
    if ([EXPORT_EXAM.ELECTRONIC_CORRECTION, EXPORT_EXAM.ELECTRONIC_EXAM]
      .includes(this.publishOption)) {
      try {
        this.loading = true;
        const { message } = await ExamGenerationProvider.publishExam(
          this.appId,
          this.$route.params.courseId,
          this.$route.params.examId,
          this.publishOption,
        );
        this.loading = false;
        this.close();
        this.successToaster(message as Lang, { duration: 5000 });
        setTimeout(() => { this.$router.go(0); }, 1000);
      } catch (err) {
        this.loading = false;
        this.close();
        this.errorToaster(err as Lang, { duration: 5000 });
      }
    }
  }
}
