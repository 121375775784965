


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { MODEL_GENERATION_ROUTE_NAME } from '../../enums';
import { Model } from '../../types/exam.type';

@Component({
  components: {

  },
})
export default class ModelView extends Vue {
    @Prop() readonly model!: Model[];

    @Prop() readonly courseId!: string;

    @Prop() readonly examId!: string;

    @Prop() readonly loading!: boolean;

    limitOption=[10, 20, 50];

    mModels: Model[] = [];

    mounted() {
      this.mModels = this.model.map((item, index) => ({ ...item, index }));
    }

    get headers() {
      return [
        {
          text: this.$t('MODEL_NUMBER'),
          sortable: false,
          align: 'center',
          value: 'name',
          class: 'grey darken-4 white--text pa-3 qb-table__header',
        },
      ];
    }

    goModelDetails(row) {
      this.$router.push({
        name: MODEL_GENERATION_ROUTE_NAME.MODEL_DETAILS,
        params: {
          courseId: this.courseId,
          examId: this.examId,
          modelNumber: row.index + 1,
        },
      });
    }
}
