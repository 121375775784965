



































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DialogComponent from '@/components/dialog.component.vue';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import ExamView from './examView.component.vue';
import ModelView from './modelView.component.vue';
import MatrixBlueprintComponent from '../MatrixBlueprint.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import SelectedCourse from '../SelectedCourse.component.vue';
import ModelTypeDialogComponent from './modelTypeDialog.vue';
import PublishTypeDialogComponent from './publishTypeDialog.vue';
import DistributeSectorsDialog from './distributeSectorsDialog.vue';
import {
  ExamData,
  Exam,
  ExamBasicAttributes,
  Model,
  ExamIlos,
  ExamSubjects,
  ExamMatrixRecord,
  LabelsConfig,
  Sector,
} from '../../types/exam.type';
import {
  APPIDS, Rights, EXPORT_EXAM, EXAM_GENERATION_ROUTE_NAME,
} from '../../enums';
import { Lang } from '../../types/general.type';

@Component({
  components: {
    DialogComponent,
    ExamView,
    ModelView,
    SelectedCourse,
    ModelTypeDialogComponent,
    MatrixBlueprintComponent,
    PublishTypeDialogComponent,
    DistributeSectorsDialog,
  },
})
export default class ExamDetails extends mixins(Toaster) {
  appId: string = APPIDS.EXAM_GENERATION;

  courseId: string = this.$route?.params.courseId;

  BackUrl = `/exam-generation/${this.courseId}/exams-list`;

  userRights: string[] = [];

  publishConfirmationRestrictions: string[] = [];

  labelsConfig: LabelsConfig = {};

  exam: Exam = {
    _id: '',
    course: { courseCode: '' },
    matrix: [{
      ilos: [],
      lectureCount: 0,
      subject: '',
    }],
    isDeleted: false,
    examInfo: {
      isSCU: false,
      isSectorsDistributed: false,
      questions: [],
      courseId: '',
      acadmicYearId: '',
      ownerId: '',
      ownerName: '',
      signature: '',
      countOfModels: 0,
      oldTotalTime: 0,
      isVerticalOrderd: false,
      examTypeName: '',
      acadmicYearName: '',
      semesterName: '',
      examLangName: '',
      shuffleName: '',
      difficultyName: '',
      difficulty: 0,
      faculty: '',
      department: '',
      examStatus: '',
      logo: '',
      examinerName: '',
      tFitness: 0,
      tQuestionsNumbers: 0,
      university: '',
      easyPercentage: 0,
      hardPercentage: 0,
      examType: '',
      examLang: '',
      semesterID: '',
      shuffle: '',
      training: '',
      examSignature: '',
      modelsMatch: '',
      examTitle: '',
      totalTime: 0,
      totalDegree: 0,
      exposureLimit: 0,
      instructorId: '',
      maxDifficulty: 100,
      mediumPercentage: 0,
      minDifficulty: 1,
      questionTypesCounts: [],
      examDate: '',
      addDegree: '',
      Degree: false,
      instructorData: {},
    },
  };

  examBasicAttrs: ExamBasicAttributes = {};

  ilosData: ExamIlos[] = [];

  subjectsData: ExamSubjects[] = [];

  examMatrix: ExamMatrixRecord[] = [];

  model: Model[] = [];

  message: Lang | null = null;

  examId: string = this.$route?.params.examId;

  loading = true;

  showDeleteConfirm = false;

  copyExamConfirm=false;

  copyExamWithModels = false;

  loadingDelete = false;

  loadingCopy = false;

  modelLength = 0;

  examStatus: string | null = null;

  modelsMatch: string | null = null;

  scuSectors: Sector[] = [];

  $refs!: {
    modelTypeDialog: ModelTypeDialogComponent;
    publishTypeDialog: PublishTypeDialogComponent;
    distributeSectorsDialog: DistributeSectorsDialog;
  };

  goBack() {
    const appFilters = this.$store.state.filters.appFilters[this.appId];
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.EXAMS_LIST,
      query: appFilters,
      params: {
        courseId: this.courseId,
      },
    });
  }

  EditExam() {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.ADD_GENERATED_EXAM,
      params: {
        courseId: this.courseId,
        examId: this.examId,
      },
    });
  }

  async loadExamData() {
    try {
      const {
        publishConfirmationRestrictions,
        examData,
        userRights,
        labelsConfig,
        scuSectors,
      }: ExamData = await ExamGenerationProvider.getGeneratedExamData(
        this.appId,
        this.courseId,
        this.examId,
        { withAttributes: true, withModels: true },
      );
      this.labelsConfig = labelsConfig as LabelsConfig;
      this.publishConfirmationRestrictions = publishConfirmationRestrictions as string[];
      this.scuSectors = scuSectors as Sector[];
      this.userRights = userRights as string[];
      this.exam = examData?.exam as Exam;
      this.examBasicAttrs = examData?.examBasicAttrs as ExamBasicAttributes;
      this.ilosData = (examData?.ilos || []) as ExamIlos[];
      this.subjectsData = (examData?.subjects || []) as ExamSubjects[];
      this.examMatrix = (examData?.exam?.matrix || []) as ExamMatrixRecord[];
      this.model = examData?.exam?.models as Model[];
      this.modelLength = this.model.length;
      this.examStatus = this.exam?.examInfo?.examStatus as string;
      this.modelsMatch = this.examBasicAttrs?.modelsMatch?.baID as string;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async mounted() {
    await this.loadExamData();
  }

  async copyExam() {
    try {
      const { newExamId, message } = await ExamGenerationProvider.copyExamData(
        this.appId,
        this.courseId,
        this.$store.state.scope.selectedScopeId,
        this.examId,
        this.copyExamWithModels,
      );
      this.loadingCopy = false;
      this.copyExamConfirm = false;
      this.successToaster(message, { duration: 5000 });
      this.$router.replace({
        name: EXAM_GENERATION_ROUTE_NAME.EXAM_DETAILS,
        params: {
          examId: newExamId,
        },
      });
      window.location.reload();
    } catch (err) {
      this.loadingCopy = false;
      this.copyExamConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async deleteExam() {
    try {
      this.loadingDelete = true;
      this.message = await ExamGenerationProvider.deleteGeneratedExamData(
        this.appId,
        this.courseId,
        this.examId,
      );
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
      this.successToaster(this.message, { duration: 5000 });
      this.goBack();
    } catch (err) {
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  get canDoAction() {
    return (
      this.userRights.includes(Rights.add)
    );
  }

  get canPublishAction() {
    return (
      this.userRights.includes(Rights.add)
      && this.examStatus !== EXPORT_EXAM.NO_MODEL
      && (!this.exam.examInfo.isSCU || this.exam.examInfo.isSectorsDistributed)
    );
  }

  get canDistributeAction(): boolean {
    return !!(
      this.userRights.includes(Rights.add)
      && this.exam.examInfo.isSCU
      && !this.exam.examInfo.isSectorsDistributed
      && this.examStatus !== EXPORT_EXAM.NO_MODEL
    );
  }

  get isSectorsDistributed(): boolean {
    return !!(this.exam.examInfo.isSCU && this.exam.examInfo.isSectorsDistributed);
  }

  openQuestionTypeDialog() {
    this.$refs.modelTypeDialog.open();
  }

  openPublishDialog() {
    this.$refs.publishTypeDialog.open();
  }

  openDistributeDialog(): void {
    this.$refs.distributeSectorsDialog.open();
  }
}
