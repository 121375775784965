















































import { Component, Vue } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';

@Component({
  components: {
    DialogComponent,
  },
})
export default class ModelTypeDialogComponent extends Vue {
  showConfirmationDialog = false;

  addModelType = '';

  open() {
    this.showConfirmationDialog = true;
  }

  close() {
    this.showConfirmationDialog = false;
  }

  onConfirmClick() {
    this.close();
    this.$router.push({
      name: 'add-model',
      params: {
        courseId: this.$route.params.courseId,
        examId: this.$route.params.examId,
      },
      query: { auto: this.addModelType },
    });
  }
}
